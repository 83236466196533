<template>
  <div class="sign-in-container">
    <!-- splash or logo area -->
    <div class="sign-in-logo">
      <img class="logo" src="@/assets/logo_with_name.svg" alt="Selia" />
    </div>

    <!-- Form -->
    <div class="sign-in-form">
      <WelcomeSlider />

      <Button
        @clicked="hdleClickStart('SignUp', 'Welcome')"
        class="margin-bottom"
        qa="sign_in_create_account"
        :text="$translations['sign-in']['sign-up-email']"
      />

      <Button
        @clicked="hdleClickStart('SignIn', 'Welcome')"
        type="outline"
        qa="sign_in_create_account"
        :text="$translations['sign-in'].title"
      />
    </div>

    <VersionComponent :app-version="$version" origin="user" />
  </div>
</template>

<script>
import WelcomeSlider from '@/views/auth/components/WelcomeSlider'
import { Button, VersionComponent } from '@seliaco/red-panda'
import { Acquisition } from '@/types/events'
export default {
  name: 'WelcomeSignIn',
  components: { Button, WelcomeSlider, VersionComponent },
  mounted () {
    const url = this.$route.fullPath
    Acquisition.welcomeView({
      title: 'Selia | Welcome',
      url
    })
  },
  methods: {
    hdleClickStart (route) {
      this.$router.push({ name: route, query: this.$route.query })
    }
  }
}
</script>

<style lang="sass">
.sign-in-container
  display: flex
  flex-direction: column
  align-items: center
  gap: 24px
  width: 100%
  max-width: 480px
.social-buttons
  display: flex
  gap: 24px
.divider
  display: flex
  align-items: center
  width: 100%
  &-line
    min-width: 50px
    height: 1px
    width: 100%
    background: var(--gray-20)
  .body-small
    white-space: nowrap
    color: var(--gray-50)
.sign-in-form
  display: flex
  flex-direction: column
  align-items: center
  width: 100%
  max-width: 480px
  .heading-display
    margin: 16px 0 40px
    text-align: center
  .forgot-password
    margin-top: 16px
    text-align: end
.link
  color: var(--purple)
  cursor: pointer
.terms-and-conditions
  line-height: 1
  text-align: center
</style>
