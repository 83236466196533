<template>
  <SwipperCarrousel
    :show-arrows="!isMobile"
    :space-between="10"
    :autoplay="{ delay: 6000, disableOnInteraction: false }"
    class="welcome-slider margin-bottom"
    :show-bullets="true"
    v-if="config.length > 0"
  >
    <template v-slot:slides>
      <div class="swiper-slide" v-for="(item, index) in config" :key="index">
        <div class="welcome-slider-slide">
          <div v-html="item.title" class="heading-big margin-y-lg" />
          <div v-html="item.subtitle" class="body margin-bottom" />
        </div>
      </div>
    </template>
  </SwipperCarrousel>
</template>

<script>
import { Settings } from '@seliaco/red-panda'
import ResponsiveMixin from '@/mixins/responsive.mixin'
import SwipperCarrousel from '@/components/misc/SwipperCarrousel'
export default {
  name: 'WelcomeSlider',
  components: { SwipperCarrousel },
  mixins: [ResponsiveMixin],
  data () {
    return {
      config: []
    }
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      const response = await Settings.get('WELCOME_SELIA')

      this.config = response.parse_value.map((item) => ({
        title: item.title[this.$store.getters.language.lang],
        subtitle: item.subtitle[this.$store.getters.language.lang]
      }))
    }
  }
}
</script>

<style lang="sass" scoped>
.welcome-slider
  text-align: center
  display: flex
  justify-content: flex-start
  align-items: center
  flex-direction: column
  max-width: 343px
  width: 480px
  height: 170px
  padding: 32px

::v-deep .swiper-container
  padding: 0px
::v-deep .swiper-button-next, ::v-deep .swiper-button-prev
  border: none
  box-shadow: initial!important
@media (max-width: 840px)
  .margin-y-lg
    margin-top: 0px!important
@media (min-width: 840px)
  .welcome-slider
    justify-content: center
    max-width: 480px
    height: 240px
</style>
